import { cn } from '@biogroup/utils/cn'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
  className,
}: {
  errors?: ListOfErrors
  id?: string
  className?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className={cn('space-y-1', className)}>
      {errorsToRender.map(e => (
        <li key={e} className="text-xs text-feedback-error">
          {e}
        </li>
      ))}
    </ul>
  )
}
